.title
{
    margin-top:-10% !important;
    padding:10% !important;
}

.listsitem:hover
{
  background-color: rgb(37,99,235)
}

  @media (max-width: 1023px) {

  }
  
  @media (max-width: 992px) {

  }
  
  @media (max-width: 910px) {

  }
  
  @media (max-width: 850px) {
 
  }
  
  
  @media (max-width: 810px) {

  }
  
  
  @media (max-width: 768px) {
  }
  
  @media (max-width: 720px) {
 
  }
  
  @media (max-width: 685px) {
    
  }
  
  @media (max-width: 650px) {

  }
  
  @media (max-width: 615px) {
  
  }
  
  @media (max-width: 580px) {
    
  }
  
  @media (max-width: 560px) {
 
  }
  
  
  @media (max-width: 540px) {
 
  }
  