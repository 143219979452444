@media only screen and (max-width: 1168px) {
.logo_modal
{
    height: 35%;
    width: 35%;
}

.text_newsletter_promotional
{
    font-size: 14px;
    text-align: center;
    margin-top:-2%;
    padding:2%;
}

.txtinfo
{
margin-top:-3%;
}

.inputInscription 
{
    width: 150px;
    height: 40px;
}

.inputInscriptionName
{
    width: 150px;
    height: 40px;
}

.txtrenseignement
{
    margin-top: -2%;
}

#dispoforfait::placeholder
{
    text-align: center;
}

.btnInscription
{
    margin-top:-2%;
}

}