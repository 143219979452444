.contact-form
{
 width: 100%;   
}

form
{
    width: 100%;
}

.bottom
{
    color:white;
    margin-top:-2%;
}

.btn
{
    background-color: rgba(205, 15, 15, 0.766);
}

#name-validation-message
{
    color:red;
}
#email-validation-message
{
    color:red;
}

#message-validation-message
{
    color:red;
}