
.bg-cover-car{
  background-image: url("./Pictures/voiture_bg.jpg");
  background-size:cover;
}

.bg-cover-boat{
  background-image: url("./Pictures/bateau_bg.jpg");
  background-size:cover;
}

.bg-cover-vr{
  background-image: url("./Pictures/vr_bg.jpg");
  background-size:cover;
}

.bg-cover-cie{
  background-image: url("./Pictures/cie_bg.jpg");
  background-size:cover;
}

.btn-info
{
  background-color: rgba(18, 18, 18, 0.75); /* Opacité de 75% pour le fond */
  color: white; 
}

.titre
{
    margin-top: -20vh; /* Marge en haut de 20% de la hauteur de l'écran */
}

@media only screen and (max-width: 600px) {
  .bg-cover-car{
    background-image: url("./Pictures/voiture_bg.jpg");
    background-size: cover;
  }

  
  .bg-cover-boat{
    background-image: url("./Pictures/bateau_bg.jpg");
    background-size:cover;
  }
  
  .bg-cover-vr{
    background-image: url("./Pictures/vr_bg.jpg");
    background-size:cover;
  }
  
  .bg-cover-cie{
    background-image: url("./Pictures/cie_bg.jpg");
   background-size:cover;
  }
}